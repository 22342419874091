<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 8" fill="none" class="mon-anim">
        <path fill="currentColor" d="M6.37 5.56 11.32.61l1.41 1.41-6.36 6.37L0 2.02 1.41.61l4.96 4.95Z" />
    </svg>
)

</script>

<style lang="scss" scoped>

.mon-anim {
    transition: transform $mon-trans-time-long $mon-timing;
}

</style>
